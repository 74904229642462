import React from "react";
import { styled } from "goober";

import { scoreColorStyle } from "@/game-val/utils.mjs";
import MannequinSvg from "@/inline-assets/mannequin.svg";

const DamageBlock = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;

  .damage-block-title {
    color: var(--shade3);
  }
`;

const MannequinFrame = styled("div")`
  display: flex;
  margin: var(--sp-4);
  svg {
    width: auto;
    height: calc(var(--sp-1) * 20);
  }
  .body-head {
    color: ${scoreColorStyle(251)};
  }
  .body-torso,
  .body-chest,
  .body-stomach {
    color: ${scoreColorStyle(231)};
  }
  .body-legs {
    color: ${scoreColorStyle(201)};
  }
`;

const DamageValues = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: var(--sp-2);
  transform: translateY(-4px);
`;

const WeaponDamageMannequin = ({ damageRange }) => {
  return (
    <DamageBlock>
      <span className="type-overline damage-block-title">
        {damageRange.range}
      </span>
      <MannequinFrame>
        <MannequinSvg />
        <DamageValues>
          <span className="type-subtitle2 body-head">{damageRange.head}</span>
          <span className="type-subtitle2 body-torso">{damageRange.body}</span>
          <span className="type-subtitle2 body-legs">{damageRange.leg}</span>
        </DamageValues>
      </MannequinFrame>
    </DamageBlock>
  );
};

export default WeaponDamageMannequin;
