import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobile, tablet } from "clutch/src/Style/style.mjs";

import type { ValorantWeapon } from "@/data-models/valorant-weapon.mjs";
import { onVolumeChange } from "@/game-val/actions.mjs";
import Video from "@/game-val/Video.jsx";
import ValMouseLeft from "@/inline-assets/val-mouse-left.svg";
import ValMouseRight from "@/inline-assets/val-mouse-right.svg";
import { buildHostedSrc } from "@/shared/VideoEmbed.jsx";
import { classNames } from "@/util/class-names.mjs";

const MouseButtons = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--sp-1);

  .mouse-bracket {
    width: 1.875rem;
    height: var(--sp-3);
    transform: translateY(-12px);
    fill: none;
    stroke: var(--shade6);
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;

    ${mobile} {
      display: none;
    }
  }
`;

const Mouse = styled("div")`
  padding: 0 var(--sp-6);
  cursor: pointer;
  color: var(--shade2);
  transition: var(--transition);

  ${mobile} {
    padding: 0 var(--sp-2);
  }

  &:hover {
    .mouse-icon {
      opacity: 1;
    }
  }

  &.selected {
    .mouse-icon {
      opacity: 1;
      color: var(--shade0);
    }
  }

  .mouse-icon {
    font-size: var(--sp-12);
    margin-bottom: var(--sp-2);
    transition: var(--transition);
    opacity: 0.5;

    ${mobile} {
      font-size: var(--sp-8);
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const WeaponInfoContainer = styled("div")`
  margin-top: -44px;

  ${mobile} {
    margin-top: 0;
  }
`;
const WeaponInfo = styled("div")`
  margin-top: -38px;
  position: relative;

  ${mobile} {
    margin-top: 0;
    padding-top: var(--sp-4);
  }
`;
const WeaponText = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0 var(--sp-22);
  padding-bottom: var(--sp-10);

  ${tablet} {
    padding: var(--sp-8);
  }
  ${mobile} {
    padding: var(--sp-4);
  }

  .weapon-byline {
    color: var(--shade3);
    margin-bottom: var(--sp-1);
  }
  .weapon-title {
    margin-bottom: var(--sp-2);
  }
  .weapon-description {
    color: var(--shade1);
  }

  ul {
    margin: 0;
    padding-left: var(--sp-6);
  }
`;

const VideoCover = styled("svg")`
  display: block;
  margin: 0 auto;
  position: relative;
  width: 381px !important;
  height: 56px !important;
  fill: var(--shade7) !important;
  filter: drop-shadow(0 -10px 6px rgba(0, 0, 0, 0.2));
  pointer-events: all;

  ${mobile} {
    display: none;
  }
`;

function getVideoSource(
  fireMode: ValorantWeapon["primary"] | ValorantWeapon["alt"],
) {
  return buildHostedSrc({
    mp4: fireMode?.video,
    webm: fireMode?.videoWebm,
  });
}

interface WeaponSprayPlayerProps {
  weapon: ValorantWeapon;
}

const WeaponSprayPlayer: React.FC<WeaponSprayPlayerProps> = ({
  weapon,
}: WeaponSprayPlayerProps) => {
  const { t } = useTranslation();
  const [selectedFireMode, setSelectedFireMode] = useState<"primary" | "alt">(
    "primary",
  );

  useEffect(() => {
    setSelectedFireMode("primary");
  }, [weapon]);

  const handleSelect = useCallback<
    (clickedMode: "primary" | "alt") => React.MouseEventHandler<HTMLDivElement>
  >(
    (clickedMode) => () => {
      setSelectedFireMode(clickedMode);
    },
    [],
  );

  if (!weapon) return null;

  const currentFireMode: ValorantWeapon["primary"] | ValorantWeapon["alt"] =
    weapon[selectedFireMode];

  const fireVideos = getVideoSource(currentFireMode);

  const hasAltFire = weapon?.alt?.kind && weapon?.alt?.video;

  return (
    <>
      {fireVideos && (
        <Video
          src={fireVideos}
          showMaximize
          isMuted
          onVolumeChange={onVolumeChange}
        />
      )}
      <WeaponInfoContainer>
        <VideoCover>
          <path d="M69.6274 0C65.384 0 61.3143 1.68571 58.3137 4.68629L17.6863 45.3137C14.6857 48.3143 10.616 50 6.37258 50H0V56H381V50H374.627C370.384 50 366.314 48.3143 363.314 45.3137L322.686 4.68629C319.686 1.68571 315.616 0 311.373 0H69.6274Z" />
        </VideoCover>
        <WeaponInfo>
          <MouseButtons>
            <svg viewBox="0 0 30 14" className="mouse-bracket">
              <path d="M1 13L11.8284 2.17157C12.5786 1.42143 13.596 1 14.6569 1H29" />
            </svg>
            <Mouse
              data-tip="Primary Fire"
              data-delay-show={500}
              onClick={handleSelect("primary")}
              {...classNames(selectedFireMode === "primary" && "selected")}
            >
              <ValMouseLeft className="mouse-icon" />
              <span className="type-overline">
                {t("val:wep.primary", "Primary")}
              </span>
            </Mouse>
            {hasAltFire && (
              <Mouse
                data-tip="Alt Fire"
                onClick={handleSelect("alt")}
                {...classNames(selectedFireMode === "alt" && "selected")}
              >
                <ValMouseRight className="mouse-icon" />
                <span className="type-overline">
                  {t("val:wep.alt", "Alt. Fire")}
                </span>
              </Mouse>
            )}
            <svg viewBox="0 0 30 14" className="mouse-bracket">
              <path d="M29 13L18.1716 2.17157C17.4214 1.42143 16.404 1 15.3431 1H1" />
            </svg>
          </MouseButtons>
          <WeaponText>
            <span className="type-overline weapon-byline">{weapon.name}</span>
            <span className="type-subtitle1 weapon-title">
              {selectedFireMode === "primary"
                ? t("val:wep.primary", "Primary")
                : t("val:wep.alt", "Alt. Fire")}
            </span>
            <div className="type-body2 weapon-description">
              <ul>
                <li>{currentFireMode.kind}</li>
                <li>
                  {t("val:fireRateValue", "Fire Rate: {{ fireRate }}", {
                    fireRate: currentFireMode.rate,
                  })}
                </li>
              </ul>
            </div>
          </WeaponText>
        </WeaponInfo>
      </WeaponInfoContainer>
    </>
  );
};

export default WeaponSprayPlayer;
