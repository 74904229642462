import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import Card from "clutch/src/Card/Card.jsx";
import { mobile } from "clutch/src/Style/style.mjs";
import i18n from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import { getWeaponImageByKey } from "@/game-val/utils.mjs";
import WeaponSprayPlayer from "@/game-val/WeaponSprayPlayer.jsx";
import ValAbilityCost from "@/inline-assets/ValAbilityCost.svg";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import WeaponDamageMannequin from "@/shared-fps/WeaponDamageMannequin.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Tabs = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: var(--sp-2);
  margin: var(--sp-4) 0;
`;

const Tab = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--shade8);
  border-radius: var(--br);
  transition: var(--transition);
  align-items: center;
  padding: var(--sp-2) var(--sp-1) var(--sp-4);
  &.tab {
    width: calc(var(--sp-1) * 45);
    &.active {
      background: var(--shade6);
    }
    img {
      max-width: 100%;
      height: calc(var(--sp-1) * 15);
    }
  }
  div.weaponInfo {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: var(--sp-1);
    left: var(--sp-3);
    div {
      display: flex;
      align-items: center;
      color: var(--shade3);
    }
    svg {
      height: var(--sp-4);
      width: var(--sp-4);
    }
  }
`;

const ContentWrapper = styled("div")`
  display: grid;
  grid-template-columns: 66.66% 33.33%;
  gap: var(--sp-4);
  ${mobile} {
    grid-template-columns: 100%;
  }
`;

const DamageRow = styled("div")`
  display: flex;
  justify-content: space-evenly;
  margin: var(--sp-5) 0;
  svg {
    width: auto;
    height: 100%;
  }
`;

const MainContent = () => css`
  display: flex;
  flex-direction: column;
  flex: 2;

  .test {
  }
`;

const SideContent = () => css`
  display: flex;
  flex-direction: column;
  flex: 1;
  img {
    max-width: 100%;
  }
`;

const StatsBlock = styled("div")`
  color: var(--shade1);

  &:not(:last-child) {
    margin-bottom: var(--sp-6);
  }
`;
const StatBlockRow = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: var(--sp-1) 0;

  span.type-caption {
    color: var(--shade2);
  }
  span.type-caption--bold {
    flex: 1;
    padding-left: var(--sp-4);
    text-align: right;
    color: var(--shade0);
  }
`;

const WeaponTab = ({ weapon: { name, cost, key }, tab, isTab }) => (
  <Tab className={isTab ? `tab${key === tab ? " active" : ""}` : ""}>
    <img src={getWeaponImageByKey(key)} />
    <div className="weaponInfo">
      <span className={isTab ? "type-caption--bold" : "type-subtitle2"}>
        {name}
      </span>
      <div>
        <ValAbilityCost />
        <span className="type-caption--bold">{cost}</span>
      </div>
    </div>
  </Tab>
);

const WeaponStatBlock = ({ title, stats }) => {
  return (
    <StatsBlock>
      {title && <span className="type-caption--bold">{title}</span>}
      {stats.map((stat) => (
        <StatBlockRow key={stat.statTitle}>
          <span className="type-caption">{stat.statTitle}</span>
          <span className="type-caption--bold">{stat.statValue}</span>
        </StatBlockRow>
      ))}
    </StatsBlock>
  );
};

export default function Weapon() {
  const { t } = useTranslation();

  const {
    val: { meta: { weapons } = {} },
  } = useSnapshot(readState);

  const {
    parameters: [tab],
  } = useRoute();

  const links = useMemo(
    () =>
      Object.entries(weapons || {}).map(([key, val]) => {
        const wepIndex = (Array.isArray(val) ? val : []).findIndex(
          (o) => o.key === tab,
        );
        const url = wepIndex === -1 ? val[0]?.key : val[wepIndex]?.key;
        return {
          url: `/valorant/weapons/${url}`,
          text: t(`val.weapons.${key.toLowerCase()}`, key),
        };
      }),
    [t, weapons, tab],
  );

  const tabs = Object.values(weapons || {}).find((arr) =>
    arr.find((o) => o.key === tab),
  );
  const weapon = tabs?.find((o) => o.key === tab);

  if (!weapon) return null;
  const { damage, primary, alt, magazine, penetration } = weapon;

  return (
    <PageContainer>
      <PageHeader title={t("val:weaponsStats.all", "Weapons")} links={links} />
      <Tabs>
        {tabs?.map((weapon) => (
          <a key={weapon.key} href={`/valorant/weapons/${weapon.key}`}>
            <WeaponTab isTab weapon={weapon} tab={tab} />
          </a>
        ))}
      </Tabs>
      <ContentWrapper>
        <Card padding="0" className={MainContent()}>
          <WeaponSprayPlayer weapon={weapon} />
        </Card>
        <Card className={SideContent()}>
          <WeaponTab weapon={weapon} tab={tab} />
          <DamageRow>
            {damage.map((damageRange) => {
              if (!damageRange?.range) return null;
              return (
                <WeaponDamageMannequin
                  key={damageRange.range}
                  damageRange={damageRange}
                />
              );
            })}
          </DamageRow>
          {primary && (
            <WeaponStatBlock
              title={t("val:wep.primaryFire", "Primary Fire")}
              stats={[
                {
                  statTitle: t("val:wep.fireMode", "Fire Mode"),
                  statValue: primary.kind,
                },
                {
                  statTitle: t("val:wep.rateOfFire", "Rate of Fire"),
                  statValue: primary.rate,
                },
              ]}
            />
          )}
          {alt && (
            <WeaponStatBlock
              title={"Alt. Fire"}
              stats={[
                {
                  statTitle: t("val:wep.fireMode", "Fire Mode"),
                  statValue: alt.kind,
                },
                {
                  statTitle: t("val:wep.rateOfFire", "Rate of Fire"),
                  statValue: alt.rate,
                },
              ]}
            />
          )}
          <WeaponStatBlock
            stats={[
              {
                statTitle: t("val:wep.magazineCapacity", "Magazine Capacity"),
                statValue: magazine,
              },
              {
                statTitle: t("val:wep.wallPenetration", "Wall Penetration"),
                statValue: penetration,
              },
            ]}
          />
        </Card>
      </ContentWrapper>
    </PageContainer>
  );
}

export function meta([tab]) {
  const { t } = i18n;
  const weapon = Object.values(readState.val.meta?.weapons || {})
    .flat()
    .find((o) => o.key === tab)?.name;
  const weaponName = t(`val:weapons.${tab}`, weapon);

  return {
    title: [
      "val:meta.weapon.title",
      "{{weaponName}} Stats - Avg. Headshot %, Kills/Match, and More",
      { weaponName },
    ],
    description: [
      "val:meta.weapon.description",
      "Find out how the {{weaponName}} performs on average and compare it to other VALORANT weapons.",
      { weaponName },
    ],
    subtitle: [
      "val:meta.weapon.subtitle",
      "Check out {{weaponName}} headshot % by range, primary and alt fire modes and rates of fire, magazine capacity, wall penetration rating",
      { weaponName },
    ],
  };
}
